import { toast, Toaster } from './lib/ui/toasts';

new Toaster({
  target: document.body
});

export const globalToast = (message, type = 'success', opts = {}) => {
  toast[type](message, {
    duration: 10_000,
    position: 'top center',
    ...opts
  });
};

window.toast = globalToast;

const components = {
  export: () => import('./components/Export.svelte'),
  CompanyProfile: () => import('./components/CompanyProfile.svelte'),
  AuthorityProfile: () => import('./components/AuthorityProfile.svelte'),
  ContractorGroupProfile: () => import('./components/ContractorGroupProfile.svelte')
};

document.querySelectorAll('[data-component]').forEach((el) => {
  const componentName = el.getAttribute('data-component');
  if (!componentName) return;

  const component = components[componentName] ?? undefined;
  if (!component) return;

  const props = JSON.parse(el.getAttribute('data-props') ?? '{}');

  component().then((module) => {
    el.innerHTML = '';
    new module.default({
      target: el,
      props
    });
  });
});
