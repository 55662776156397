import { createRoot } from 'react-dom/client';
import { lazy, StrictMode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const components = {
  AIChat: lazy(() => import('./sections/AIChat'))
};

const queryClient = new QueryClient({});

document.querySelectorAll('[data-component]').forEach((el) => {
  const componentName = el.getAttribute('data-component');
  if (!componentName) return;

  const Component = components[componentName] ?? undefined;
  if (!Component) return;

  const props = JSON.parse(el.getAttribute('data-props') ?? '{}');

  el.innerHTML = '';
  createRoot(el).render(
    <StrictMode>
      <ErrorBoundary fallback={<div>Something bad happened...</div>}>
        <QueryClientProvider client={queryClient}>
          <Component {...props} />
        </QueryClientProvider>
      </ErrorBoundary>
    </StrictMode>
  );
});
