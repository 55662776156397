const urlSearchParams =
  typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;

// eslint-disable-next-line no-console
const defaultLogger = console.log;

const logger = (message, ...optionalDetails) => {
  if (import.meta.env.MODE === 'development' || urlSearchParams?.has('debugOnprod')) {
    defaultLogger(`%cHX Logger:%c`, 'color: yellow;', 'color: white', message, ...optionalDetails);
  }
};

// Expose logger to the window
// window['console'] = {
//     ...window['console'],
//     log: logger
// }
window['logger'] = logger;

export default logger;
